import "./family-law-page.styles.css";
import "../areas-of-practise.styles.css";

import AreaOfPractiseHeader from "../../../components/area-of-practise-header/area-of-practise-header.component";
import AreaOfPractiseSidebar from "../../../components/area-of-practise-sidebar/area-of-practise-sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";

const FamilyLawPage = () => (
  <div className="areas-of-practise-view min-view">
    <Navbar />
    <div className="family-law-head">
      <AreaOfPractiseHeader title="Family Law" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-12">
          <p className="areas-of-practise-title">Family Law</p>
          <p>Breakups can be a difficult and emotional time for all involved particularly if there are children to be considered.</p>
          <p>We at Grainne Dolan &amp; Co. Solicitors seek to deal with your situation in a common sense non judgemental way and assist you in navigating the process and advise on the legal implications of your considered instructions.</p>
          <p>We deal with the following;</p>
          <p>&#8226; Separation Agreements</p>
          <p>&#8226; Judicial Separation</p>
          <p>&#8226; Divorce</p>
          <p>&#8226; Maintenance</p>
          <p>&#8226; Custody</p>
          <p>&#8226; Access</p>
          <p>&#8226; Barring Orders</p>
          <p>&#8226; Guardianship</p>
          <p>&#8226; Pension Adjustment Orders</p>
        </div>
        <div className="col-md-1 col-12"></div>
        <div className="col-md-3 col-12">
          <AreaOfPractiseSidebar />
        </div>
      </div>
    </div>
  </div>
);

export default FamilyLawPage;
