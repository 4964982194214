import "./contact-page.styles.css";

import ContactForm from "../../components/contact-form/contact-form.component";
import Navbar from "../../components/navbar/navbar.component";

const ContactPage = () => (
  <div className="min-view">
    <Navbar />
    <ContactForm />
  </div>
);

export default ContactPage;
