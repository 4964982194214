import "./contact-form.styles.css";

const ContactForm = () => (
  <div className="contact-form">
    <div className="container">
      <p className="text-center">Have a question? Just ask!</p>
      <div className="row">
        <div className="col-12 col-md-6">
          <form onSubmit="submit" name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-group">
              <input name="name" type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Name" required />
            </div>
            <div className="form-group">
              <input name="email" type="email" className="form-control" id="email" placeholder="Email" required />
            </div>
            <div className="form-group">
              <input name="phone" type="tel" className="form-control" id="telephone" placeholder="Number" required />
            </div>
            <div className="form-group">
              <textarea name="message" className="form-control" id="Message" placeholder="Message" rows="6" required></textarea>
            </div>
            <button type="submit" className="btn btn-primary btn-form mb-5 mb-md-0">
              Submit
            </button>
          </form>
        </div>
        <div className="col-12 col-md-6">
          <div className="z-depth-1-half map-container" style={{ height: "400px" }}>
            <iframe title="Grainne Dolan & Co Solicitors Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18772.318699976022!2d-6.733865161442289!3d53.97543882739082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860b22f96442f39%3A0x8d00a7cb35501181!2sDrummond%20Etra%2C%20Co.%20Monaghan%2C%20A81%20X070!5e0!3m2!1sen!2sie!4v1613708062550!5m2!1sen!2sie" width="600" height="450" frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactForm;
