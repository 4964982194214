import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from "./utilities/scroll-to-top.component";

import Footer from "./components/footer/footer.component";

import HomePage from "./pages/home-page/home-page.component";
import AboutPage from "./pages/about-page/about-page.component";

import PersonalInjuryPage from "./pages/areas-of-practise/personal-injury-page/personal-injury-page.component";
import WillsPage from "./pages/areas-of-practise/wills-page/wills-page.component";
import ProbatePage from "./pages/areas-of-practise/probate-page/probate-page.component";
import ConveyancingPage from "./pages/areas-of-practise/conveyancing-page/conveyancing-page.component";
import FamilyLawPage from "./pages/areas-of-practise/family-law-page/family-law-page.component";
import EmploymentLawPage from "./pages/areas-of-practise/employment-law-page/employment-law-page.component";
import ContactPage from "./pages/contact-page/contact-page.component";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/personal-injury" component={PersonalInjuryPage} />
        <Route exact path="/wills" component={WillsPage} />
        <Route exact path="/probate" component={ProbatePage} />
        <Route exact path="/conveyancing" component={ConveyancingPage} />
        <Route exact path="/family-law" component={FamilyLawPage} />
        <Route exact path="/employment-law" component={EmploymentLawPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route component={HomePage} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
