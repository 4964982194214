import "./employment-law-page.styles.css";
import "../areas-of-practise.styles.css";

import AreaOfPractiseHeader from "../../../components/area-of-practise-header/area-of-practise-header.component";
import AreaOfPractiseSidebar from "../../../components/area-of-practise-sidebar/area-of-practise-sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";

const EmploymentLawPage = () => (
  <div className="areas-of-practise-view min-view">
    <Navbar />
    <div className="employment-head">
      <AreaOfPractiseHeader title="Employment Law" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-12">
          <p className="areas-of-practise-title">Employment Law</p>
          <p>At Grainne Dolan &amp; Co Solicitors we advise clients on a wide range of employment issues including the following:</p>
          <p>• Unfair Dismissal</p>
          <p>• Constructive Dismissal</p>
          <p>• Harassment and Bullying</p>
          <p>• Equality Rights</p>
        </div>
        <div className="col-md-1 col-12"></div>
        <div className="col-md-3 col-12">
          <AreaOfPractiseSidebar />
        </div>
      </div>
    </div>
  </div>
);

export default EmploymentLawPage;
